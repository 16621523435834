import './App.css';
import Accra_home from './pages/accra_home';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import FindAccomodation from './pages/findAccomodation';
import SignUppage from './pages/signupPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './pages/Layout';
import SigninPage from './pages/signinPage';
import OtpPage from "./pages/otpPage";
import RequireAuth from './components/requireAuth';
import ProfilePage from './pages/profilePages/profileInfoPage';
import RequireOtp from './components/requireOtp';
import RecentBooking from './pages/profilePages/recentBooking';
import TopLuxury from './pages/topLuxury';
import PendingBooking from './pages/profilePages/pendingBooking';
import PasswordSecurity from './pages/profilePages/passwordSecurity';
import MyProperties from './pages/profilePages/myProperties';
import Wishlist from './pages/profilePages/wishlist';
import RefundRequest from './pages/profilePages/refundRequest';
import Reviews from './pages/profilePages/reviews';
import Notifications from './pages/profilePages/notifications';
import HelpCenter from './pages/helpCenter';
import AddProperty from './pages/property/addProperty';
import PropertyDetailPage from './pages/property/propertyDetailPage';
import MyPropertyDetail from './pages/profilePages/myPropertyDetail';
import EditProperty from './pages/property/editProperty';
import Events from './pages/events';
import EventDetail from './pages/eventDetail';
import Blog from './pages/blog';
import FAQ from './pages/FAQ';
import Contact from './pages/contact';
import Backtotop from './components/backtotop';
import PrivacyPolicy from './pages/privacyPolicy';
import Termsandconditions from './pages/termsandconditions';
import DashboardLayout from './components/dashboardLayout';
import BlogDetail from "./pages/blogDetail";
import PaymentHistoryHost from './pages/transaction/paymentHistoryHost';
import PayoutForm from './pages/transaction/payoutForm';
import PayOutHistory from './pages/profilePages/payOutHistory';
import ScrollToTop from './components/scrollToTop';
import PropertyBookingPaymentDetail from './pages/property/propertyBookingDetail';
import Commision from './pages/partners/commision/commision';
import Dashboard from './pages/partners/dashboard/dashboard';
import Users from './pages/partners/users/users';
import Promo_Codes from './pages/partners/promo_codes/promo_codes';
import Settings from './pages/partners/settings/settings';
import LoginPage from './pages/partners/authentication/login';
import OTPPage from './pages/partners/authentication/otppage';
import RequireAuthPartner from './components/requireAuthPartners';
import RequireOtpPartner from './components/requireOtpPartners';
import Custom404Page from './pages/custom404Page';
import Partner404Page from './pages/partners/Partner404Page';

// New component to handle logic requiring useLocation
function AppContent() {
  const location = useLocation();

  useEffect(() => {
    let styles = [];

    const loadStyles = async () => {
      if (location.pathname.includes('/partner')) {
        styles.push(await import("./assets/css/bootstrap.min.css"));
        styles.push(await import("./assets/css/typography.css"));
        styles.push(await import("./assets/css/style.css"));
        styles.push(await import("./assets/css/responsive.css"));
      }
    };

    loadStyles();

    return () => {
      // Cleanup: Remove styles when unmounting or path changes
      styles.forEach((style) => {
        const link = document.querySelector(`link[href="${style.default}"]`);
        if (link) {
          link.parentNode.removeChild(link);
        }
      });
    };
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/register" element={<SignUppage />} />
      <Route path="/login" element={<SigninPage />} />
      <Route path="/verify" element={<OtpPage />} />
      <Route path="/partner/verify" element={<OTPPage />} />
      <Route path="/partner/login" element={<LoginPage />} />
      <Route path="/partner/verify-email" element={<OTPPage />} />
      <Route path="*" element={<Custom404Page />} />

      <Route element={<RequireAuthPartner />}>
        <Route element={<RequireOtpPartner />}>
          <Route path="/partner" element={<Dashboard />} />
          <Route path="/partner/commision" element={<Commision />} />
          <Route path="/partner/users" element={<Users />} />
          <Route path="/partner/promo_codes" element={<Promo_Codes />} />
          <Route path="/partner/settings" element={<Settings />} />
        </Route>
      </Route>
      <Route element={<RequireOtp />}>
        <Route path="/" element={<Layout><Accra_home /></Layout>} />
        <Route path="properties/luxury" element={<Layout><TopLuxury /></Layout>} />
        <Route path="/properties" element={<Layout><FindAccomodation /></Layout>} />
        <Route path="/experiences" element={<Layout><Events /></Layout>} />
        <Route element={<RequireAuth />}>
          <Route path="/dashboard/" element={<DashboardLayout />}>
            <Route path="wishlist" element={<Wishlist />} />
            <Route path="password-security" element={<PasswordSecurity />} />
            <Route path="personal-info" element={<ProfilePage />} />
            <Route path="recent-booking" element={<RecentBooking />} />
            <Route path="my-properties/approvals" element={<PendingBooking />} />
            <Route path="my-properties" element={<MyProperties />} />
            <Route path="refund-request" element={<RefundRequest />} />
            <Route path="reviews" element={<Reviews />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="my-properties/edit/:id" element={<EditProperty />} />
            <Route path="payment-history" element={<PaymentHistoryHost />} />
            <Route path="payout-history" element={<PayOutHistory />} />
          </Route>
          <Route path="payment-detail/:id" element={<Layout><PropertyBookingPaymentDetail /></Layout>} />
          <Route path="my-properties/:id" element={<Layout><MyPropertyDetail /></Layout>} />
          <Route path="my-properties/edit/:id" element={<Layout><EditProperty /></Layout>} />
          <Route path="/dashboard/my-properties/add" element={<Layout><AddProperty /></Layout>} />
          <Route path="/dashboard/payout-form" element={<Layout><PayoutForm /></Layout>} />
        </Route>
        <Route path="/help" element={<Layout><HelpCenter /></Layout>} />
      </Route>
      <Route path="/properties/detail/:id" element={<Layout><PropertyDetailPage /></Layout>} />
      <Route path="/experiences/detail/:id" element={<Layout><EventDetail /></Layout>} />
      <Route path="/posts" element={<Layout><Blog /></Layout>} />
      <Route path="/posts/detail/:id" element={<Layout><BlogDetail /></Layout>} />
      <Route path="/FAQs" element={<Layout><FAQ /></Layout>} />
      <Route path="/contact" element={<Layout><Contact /></Layout>} />
      <Route path="/privacy-policy" element={<Layout><PrivacyPolicy /></Layout>} />
      <Route path="/terms-of-service" element={<Layout><Termsandconditions /></Layout>} />
    </Routes>
  );
}

function App() {
  return (
    <div className="page-wrapper">
      <Router>
        <ScrollToTop />
        <AppContent /> {/* Render the new component here */}
      </Router>
      <Backtotop />
    </div>
  );
}

export default App;