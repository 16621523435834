
import React, { useEffect, useState ,useCallback,useRef} from 'react'
import PageLoading from '../components/landingcomponents/pageLoader';
import Categoriescarousel from '../components/landingcomponents/categoriescarousel';
import image6 from "../asserts/img/real-estate/catalog/06.jpg"
import image7 from "../asserts/img/real-estate/catalog/07.jpg"
import image8 from "../asserts/img/real-estate/catalog/08.jpg"
import PropertyCard from '../components/propertyCard';
import SidebarAccomodation from '../components/sidebarAccomodation';
import { useGetAllPropertiesMutation } from '../features/property/propertySlice';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useGetCategoriesMutation, useGetAmenitiesMutation, useGetPrevilegeMutation, useGetRegionsMutation } from '../features/property/propertySlice';
import Autocomplete from "react-google-autocomplete";
import debounce from 'lodash.debounce';
import { setRegion } from 'react-geocode';
import Carousel from 'react-elastic-carousel';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';

const breakPoints = [
  { width: 1, itemsToShow: 1, itemsToScroll: 1, infinite: false, swipe: true, speed: 500 },
  { width: 500, itemsToShow: 2, itemsToScroll: 1, infinite: false, swipe: true, speed: 500 },
  { width: 768, itemsToShow: 4, itemsToScroll: 4,  infinite: false, swipe: true, speed: 500 }
];




function FindAccomodation() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [getproperties, { isLoading }] = useGetAllPropertiesMutation()
  const [categories, setCategories] = useState()
  const [propertyData, setPropertyData] = useState()
  const [amenities, setAmenities] = useState()
  const [previleges, setPrevileges] = useState()
  const addressref=useRef()
  const regionref=useRef()
  const cityref=useRef()
  const [getcategories, { isLoading: isLoadingCategories }] = useGetCategoriesMutation()
  const [getamenities, { isLoading: isLoadingAmenities }] = useGetAmenitiesMutation()
  const [getprevileges, { isLoading: isLoadingPrevileges }] = useGetPrevilegeMutation()
  const [getregions, { isLoading: isLoadingRegions }] = useGetRegionsMutation()
  const [address, setAddress] = useState(searchParams.get('address') || '');
  const [cityvalue, setCity] = useState(searchParams.get('city') || '');
  const [region, setRegion] = useState(searchParams.get('region') || '');
  const [priceminvalue, setPriceminvalue] = useState(searchParams.get('pricemin') || '');
  const [pricemaxvalue, setPricemaxvalue] = useState(searchParams.get('pricemax') || '');
  const [sidebar,setSidebar]=useState(false)

  
  const location = useLocation();
  const navigate=useNavigate()


     

const priceminref=useRef()
const pricemaxref=useRef()







const handlePriceChange = () => {
  setPriceminvalue(priceminref.current.value);
  setPricemaxvalue(pricemaxref.current.value);
  debouncedCheckIfDoneTyping1();
};

const checkIfDoneTyping1 = () => {
  setPriceminvalue(priceminref.current.value);
  setPricemaxvalue(pricemaxref.current.value);
  searchParams.set('pricemin', priceminref.current.value);
  searchParams.set('pricemax', pricemaxref.current.value);
  setSearchParams(searchParams);
  handlegetproperties();
};


// Use useCallback to ensure the debounced function is not recreated on every render
const debouncedCheckIfDoneTyping1 = useCallback(debounce(checkIfDoneTyping1, 3000), []);

useEffect(() => {
  // Cleanup the debounced function on component unmount
  return () => {
    debouncedCheckIfDoneTyping1.cancel();
  };
}, [debouncedCheckIfDoneTyping1]);












  const handleAddressChange = () => {
    setAddress(addressref.current.value);

    debouncedCheckIfDoneTyping();
  };


  const handleRegionChange = () => {
    setRegion(regionref.current.value);

    debouncedCheckIfDoneTyping3();
  };


  const handleCityChange = () => {
    setCity(cityref.current.value);

    debouncedCheckIfDoneTyping2();
  };



  const checkIfDoneTyping = () => {
    searchParams.set('address', addressref.current.value);
    setSearchParams(searchParams);
    setAddress(addressref.current.value);
    handlegetproperties();
  };


  
  const checkIfDoneTyping2 = () => {
    let cityname = cityref.current.value.replace("Ghana", '').replace(",", "");
    searchParams.set('city', cityname);
    setSearchParams(searchParams);
    setCity(cityref.current.value);
    handlegetproperties();
  };



  const checkIfDoneTyping3 = () => {
    let regionname = regionref.current.value.replace("Region", '').replace("Ghana", '').replace(",", "");
    searchParams.set('region', regionname);
    setSearchParams(searchParams);
    setRegion(regionref.current.value);
    handlegetproperties();
  };


  const debouncedCheckIfDoneTyping = useCallback(debounce(checkIfDoneTyping, 3000), []);
  const debouncedCheckIfDoneTyping2 = useCallback(debounce(checkIfDoneTyping2, 3000), []);
  const debouncedCheckIfDoneTyping3 = useCallback(debounce(checkIfDoneTyping3, 3000), []);

  useEffect(() => {
    // Cleanup the debounced function on component unmount
    return () => {
      debouncedCheckIfDoneTyping.cancel();
    };
  }, [debouncedCheckIfDoneTyping]);


  
  useEffect(() => {
    // Cleanup the debounced function on component unmount
    return () => {
      debouncedCheckIfDoneTyping2.cancel();
    };
  }, [debouncedCheckIfDoneTyping2]);


  useEffect(() => {
    // Cleanup the debounced function on component unmount
    return () => {
      debouncedCheckIfDoneTyping3.cancel();
    };
  }, [debouncedCheckIfDoneTyping3]);



  const handleBedroomChange = (e) => {
    searchParams.set('bedroom', e.target.value);
    setSearchParams(searchParams);
    handlegetproperties();
  };


  const handleBathroomChange = (e) => {
    searchParams.set('bathroom', e.target.value);
    setSearchParams(searchParams);
    handlegetproperties();
  };

  const colors = ['warning', 'success', 'primary', 'accent', 'info'];

  
  const handleCategoriesChange = (e) => {
    const currentCategory = searchParams.get('category_name') || '';
    if (currentCategory.includes(`${e.target.value},`)) {
      const newcategorystring = currentCategory.replace(`${e.target.value},`, '');
      searchParams.set('category_name', newcategorystring);
    } else if (currentCategory.includes(`,${e.target.value}`)) {
      const newcategorystring = currentCategory.replace(`,${e.target.value}`, '');
      searchParams.set('category_name', newcategorystring);
    } else if (currentCategory.includes(e.target.value)) {
      const newcategorystring = currentCategory.replace(e.target.value, '');
      searchParams.set('category_name', newcategorystring);
    } else {
      searchParams.set('category_name', currentCategory + ',' + e.target.value);
    }
    setSearchParams(searchParams);
    handlegetproperties();
  };




  const handleAmenitiesChange = (e) => {
    const currentAmenity = searchParams.get('amenity_name') || '';
    if (currentAmenity.includes(`${e.target.value},`)) {
      const newamenitystring = currentAmenity.replace(`${e.target.value},`, '');
      searchParams.set('amenity_name', newamenitystring);
    } else if (currentAmenity.includes(`,${e.target.value}`)) {
      const newamenitystring = currentAmenity.replace(`,${e.target.value}`, '');
      searchParams.set('amenity_name', newamenitystring);
    } else if (currentAmenity.includes(e.target.value)) {
      const newamenitystring = currentAmenity.replace(e.target.value, '');
      searchParams.set('amenity_name', newamenitystring);
    } else {
      searchParams.set('amenity_name', currentAmenity + ',' + e.target.value);
    }
    setSearchParams(searchParams);
    handlegetproperties();
  };


  const handlePrevilegesChange = (e) => {
    const currentPrevilege = searchParams.get('previlege_name') || '';
    if (currentPrevilege.includes(`${e.target.value},`)) {
      const previlegestring = currentPrevilege.replace(`${e.target.value},`, '');
      searchParams.set('previlege_name', previlegestring);
    } else if (currentPrevilege.includes(`,${e.target.value}`)) {
      const previlegestring = currentPrevilege.replace(`,${e.target.value}`, '');
      searchParams.set('previlege_name', previlegestring);
    } else if (currentPrevilege.includes(e.target.value)) {
      const previlegestring = currentPrevilege.replace(e.target.value, '');
      searchParams.set('previlege_name', previlegestring);
    } else {
      searchParams.set('previlege_name', e.target.value);
    }
    setSearchParams(searchParams);
    handlegetproperties();
  };


const [nextProperties,setNextProperties]=useState()
const [previousProperties,setPreviousProperties]=useState()
const [page,setPage]=useState(1)
const [pageCount,setPageCount]=useState()
const [count,setCount]=useState()




const handlegetproperties = async (pageparam) => {
  const params = {
    title__icontains: searchParams.get('title') || '',
    address__icontains: searchParams.get('address') || '',
    region__icontains: searchParams.get('region') || '',
    city__icontains: searchParams.get('city') || '',
    bedroom: searchParams.get('bedroom') || '',
    parking_spot: searchParams.get('parking_spot') || '',
    bathroom: searchParams.get('bathroom') || '',
    amenity: searchParams.get('amenity_name') || '',
    property_permission: searchParams.get('permission') || '',
    privilege: searchParams.get('previlege') || '',
    per_person: `${searchParams.get('pricemin') || '0'},${searchParams.get('pricemax') || '10000000000000000'}`,
    category: searchParams.get('category_name') || '',
    page: pageparam
  };

  try {
    const response = await getproperties(params);
    setPropertyData(response.data);
    setCount(response.data.count);
    setNextProperties(response.data.next);
    setPreviousProperties(response.data.previous);
    setPageCount(Math.ceil(response.data.count / 10));
  } catch (e) {
    console.log(e);
  }
};

 



  const handleGetCategories = async () => {
    try {

      const categories = await getcategories()
      setCategories(null)
      setCategories(categories.data)
      console.log('gettting property')




    } catch (e) {
      console.log(e)

    }
  }




  const handleGetAmenities = async () => {
    try {

      const amenities = await getamenities()
      console.log(amenities)

      setAmenities(amenities.data)

    } catch (e) {
      console.log(e)

    }
  }


  const handleGetPrevileges = async () => {
    try {

      const previleges = await getprevileges()

      setPrevileges(previleges.data)

    } catch (e) {
      console.log(e)

    }
  }

 
  
useEffect(()=>{
  handleGetCategories()
},[])

  useEffect(() => {
   
    handleGetAmenities()
    handleGetPrevileges()
   
    handlegetproperties(page)
    console.log(useEffect)
  }, [])


  
const handlePagination=(page)=>{
  setPage(page);
  handlegetproperties(page);
}



  const sidebarref=useRef()
  const handleClickOutside = (event) => {
    if (sidebarref.current && !sidebarref.current.contains(event.target)) {
      setSidebar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  const handleReset = () => {
    searchParams.delete('title');
    searchParams.delete('address');
    searchParams.delete('region');
    searchParams.delete('city');
    searchParams.delete('bedroom');
    searchParams.delete('parking_spot');
    searchParams.delete('bathroom');
    searchParams.delete('amenity_name');
    searchParams.delete('permission');
    searchParams.delete('previlege');
    searchParams.delete('pricemin');
    searchParams.delete('pricemax');
    searchParams.delete('category_name');
    setSearchParams(searchParams);
    handlegetproperties();
    setSidebar(false);
  };



  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


useEffect(() => {
  // Handler to call on window resize
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Add event listener
  window.addEventListener('resize', handleResize);

  // Call handler right away so state gets updated with initial window size
  handleResize();

  // Remove event listener on cleanup
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

const [currentIndex, setCurrentIndex] = useState(0);
const totalItems = 6; // Adjust this to the total number of items you have

const handleNextEnd = (currentItem, pageIndex) => {
  setCurrentIndex(pageIndex);
};

const handlePrevEnd = (currentItem, pageIndex) => {
  setCurrentIndex(pageIndex);
};

const handleChange = (currentItem, pageIndex) => {
  setCurrentIndex(pageIndex);
};


const CustomArrow = ({ type, onClick, isEdge }) => {
  const isPrevArrow = type === 'PREV';
  const isNextArrow = type === 'NEXT';

  return (
    <button
      onClick={onClick}
      disabled={isEdge}
      style={{
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        fontSize: '12px'
      }}
    >
      {isPrevArrow ? <i class="fi-chevron-left"></i> : <i class="fi-chevron-right"></i>}
    </button>
  );
};



  return (
    <div className="container-fluid mt-5 pt-5 p-0">
      <PageLoading />


      <div className="container d-flex flex-wrap flex-column flex-sm-row pb-5 mb-md-3">

        {
          isLoadingCategories ? Array.from({ length: 5 }).map(() => {
            return (

              <a className="icon-box card flex-row align-items-center flex-shrink-0 card-hover border-0 shadow-sm rounded-pill py-2 ps-2 pe-4 mb-2 mb-sm-3 me-sm-3 me-xxl-4" >
                <Skeleton circle={true} style={{ width: "40px", height: "40px", marginRight: "5px" }} />
                <Skeleton style={{ width: "100px" }} /></a>
            )
          }) :
          
            <Carousel
    renderArrow={CustomArrow}
    onNextEnd={handleNextEnd}
      onPrevEnd={handlePrevEnd}
      onChange={handleChange}
   itemsToShow={3}
  itemsToScroll={2}
  showArrows={true}
  showDots={false}
  enableAutoPlay={false}
  autoPlaySpeed={1500}
  infinite={true}
  pagination={false}
  disableArrowsOnEnd={true}
  focusOnSelect={true}
  initialActiveIndex={0}
  breakPoints={breakPoints}
     > 
      {categories && categories.map((category, index) => {
            return (


              <a key={category} className={` ${localStorage.getItem('category_id') == category.id ? 'disabled' : ''} disabled  icon-box card flex-row align-items-center flex-shrink-0 card-hover border-0 shadow-sm rounded-pill py-2 ps-2 pe-4 mb-2 mb-sm-3 me-sm-3 me-xxl-4`} >
                <div className={`icon-box-media bg-faded-${colors[index % colors.length]} text-${colors[index % colors.length]} rounded-circle me-2`}><i className={`${category.icon}`}></i></div>
                <h3 className="icon-box-title fs-sm ps-1 pe-2 mb-0">{category.name} </h3></a>


            )
          })
          }
     </Carousel>
          
        
        }


        <div>

        </div>
      </div>
      <div className="row g-0 mt-n3">
        <aside className="col-lg-4 col-xl-3 border-top-lg border-end-lg shadow-sm px-3 px-xl-4 px-xxl-5 pt-lg-2">
        <div ref={sidebarref} className={`offcanvas offcanvas-start offcanvas-collapse  ${sidebar?"show":""}`} id="filters-sidebar">
            <div className="offcanvas-header d-flex d-lg-none align-items-center">
              <h2 className="h5 mb-0">Filters</h2>
              <button onClick={()=>{setSidebar(false)}} className="btn-close" type="button" data-bs-dismiss="offcanvas"></button>
            </div>

            <div className="offcanvas-body py-lg-4">
              <div className="pb-4 mb-2">

              <h3 className="h6">Region</h3>

<Autocomplete
  apiKey={"AIzaSyADkWwYDwUmTWehisZ_iv5V09S_xEw4yWg"}  // Replace with your actual API key

  options={{
    types: ['administrative_area_level_1'],  // restrict search to addresses only
    componentRestrictions: { country: "Gh" }  // limit search to United States
  }}
  value={region}
  onPlaceSelected={handleRegionChange}
 
onChange={handleRegionChange}
  ref={regionref}

  className='form-control mb-2'
  placeholder="region"
 


/>


<h3 className="h6">City</h3>

<Autocomplete
  apiKey={"AIzaSyADkWwYDwUmTWehisZ_iv5V09S_xEw4yWg"}  // Replace with your actual API key

  options={{
    types: ['(cities)'],   // restrict search to addresses only
    componentRestrictions: { country: "Gh" }  // limit search to United States
  }}
  value={cityvalue}
  onPlaceSelected={handleCityChange}
 
onChange={handleCityChange}
  ref={cityref}

  className='form-control mb-2'
  placeholder="city"
 


/>
                <h3 className="h6">Address</h3>

                <Autocomplete
                  apiKey={"AIzaSyADkWwYDwUmTWehisZ_iv5V09S_xEw4yWg"}  // Replace with your actual API key

                  options={{
                    types: ['address'],  // restrict search to addresses only
                    componentRestrictions: { country: "Gh" }  // limit search to United States
                  }}
                  value={address}
                  onPlaceSelected={handleAddressChange}
                 
        onChange={handleAddressChange}
                  ref={addressref}

                  className='form-control mb-3'
                  placeholder="address"
                 
                

                />


            
              </div>
              <div className="pb-4 mb-2">
                <h3 className="h6">Property category</h3>
                <div className="overflow-auto" style={{ height: '11rem' }}>


                  {categories &&
                    categories && categories.map((category, index) => {
                      return (
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" id="house" checked={localStorage.getItem('category_name')?localStorage.getItem('category_name').includes(category.name):false} onChange={(e)=>{handleCategoriesChange(e)}} value={category.name} />
                          <label className="form-check-label fs-sm" for="house">{category.name}</label>
                        </div>


                      )
                    })
                  }



                </div>
              </div>
              <div className="pb-4 mb-2">
                <h3 className="h6">Price</h3>
                <div className="range-slider" data-start-min="1100" data-start-max="3000" data-min="200" data-max="5000" data-step="100">
                  <div className="d-flex align-items-center">
                    <div className="w-50 pe-2">
                      <div className="input-group"><span className="input-group-text fs-base">₵</span>
                        <input onChange={handlePriceChange} value={priceminvalue} ref={priceminref} className="form-control range-slider-value-min" type="text" />
                      </div>
                    </div>
                    <div className="text-muted">&mdash;</div>
                    <div className="w-50 ps-2">
                      <div className="input-group"><span className="input-group-text fs-base">₵</span>
                        <input onChange={handlePriceChange} value={pricemaxvalue}  ref={pricemaxref} className="form-control range-slider-value-max" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pb-4 mb-2">
                <h3 className="h6 pt-1">Beds &amp; baths</h3>
                <label className="d-block fs-sm mb-1">Bedrooms</label>
                <div className="btn-group btn-group-sm" role="group" aria-label="Choose number of bedrooms">
                  <input className="btn-check" type="radio" id="studio" value={1}  onChange={(e)=>{handleBedroomChange(e)}} name="bedrooms" />
                  <label className="btn btn-outline-secondary fw-normal" for="bedrooms-1">1</label>
                  <input className="btn-check" type="radio" id="bedrooms-2" value={2}  onChange={(e)=>{handleBedroomChange(e)}} name="bedrooms" />
                  <label className="btn btn-outline-secondary fw-normal" for="bedrooms-2">2</label>
                  <input className="btn-check" type="radio" id="bedrooms-3" value={3}  onChange={(e)=>{handleBedroomChange(e)}} name="bedrooms" />
                  <label className="btn btn-outline-secondary fw-normal" for="bedrooms-3">3</label>
                  <input className="btn-check" type="radio" id="bedrooms-4" value={4}  onChange={(e)=>{handleBedroomChange(e)}}  name="bedrooms" />
                  <label className="btn btn-outline-secondary fw-normal" for="bedrooms-4">4</label>
                  <input className="btn-check" type="radio" id="bedrooms-5"  value={5}  onChange={(e)=>{handleBedroomChange(e)}} name="bedrooms" />
                  <label className="btn btn-outline-secondary fw-normal" for="bedrooms-5">5+</label>

                </div>
                <label className="d-block fs-sm pt-2 my-1">Bathrooms</label>
                <div className="btn-group btn-group-sm" role="group" aria-label="Choose number of bathrooms">
                  <input className="btn-check" type="radio" onChange={(e)=>{handleBathroomChange(e)}} value={1} id="bathrooms-1" name="bathrooms" />
                  <label className="btn btn-outline-secondary fw-normal" for="bathrooms-1">1</label>
                  <input className="btn-check" type="radio" id="bathrooms-2"  onChange={(e)=>{handleBathroomChange(e)}} value={2}  name="bathrooms" />
                  <label className="btn btn-outline-secondary fw-normal" for="bathrooms-2">2</label>
                  <input className="btn-check" type="radio" id="bathrooms-3"   onChange={(e)=>{handleBathroomChange(e)}} value={3}   name="bathrooms" />
                  <label className="btn btn-outline-secondary fw-normal" for="bathrooms-3">3</label>
                  <input className="btn-check" type="radio" id="bathrooms-4" name="bathrooms"   onChange={(e)=>{handleBathroomChange(e)}} value={4}  />
                  <label className="btn btn-outline-secondary fw-normal" for="bathrooms-4">4</label>
                </div>
              </div>

              <div className="pb-4 mb-2">
                <h3 className="h6">Amenities</h3>
                <div className="overflow-auto" style={{ height: "11rem" }}>
                  {
                    amenities && amenities.map((amenity, index) => {
                      return (
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" id="air-condition" value={amenity.name}  checked={localStorage.getItem('amenity_name')?localStorage.getItem('amenity_name').includes(amenity.name):false} onChange={(e)=>{handleAmenitiesChange(e)}}  />
                          <label className="form-check-label fs-sm" for="air-condition">{amenity.name}</label>
                        </div>
                      )
                    })
                  }


                </div>
              </div>
              <div className="pb-4 mb-2">
                <h3 className="h6">Previleges</h3>
                {
                  previleges && previleges.map((previlege, index) => {
                    return (
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="air-condition" value={previlege.name} checked={localStorage.getItem('previlege_name')?localStorage.getItem('previlege_name').includes(previlege.name):false} onChange={(e)=>{handlePrevilegesChange(e)}} />
                        <label className="form-check-label fs-sm" for="air-condition">{previlege.name} allowed</label>
                      </div>
                    )
                  })
                }


              </div>
              <div className="border-top py-4">
                <button onClick={handleReset} className="btn btn-outline-primary" type="button"><i className="fi-rotate-right me-2"></i>Reset filters</button>
              </div>
            </div>
          </div>
          {
            sidebar?
            <div class={`offcanvas-backdrop fade  show`} ></div>:""

          }
         
        </aside>
        <div class="col-lg-8 col-xl-9 position-relative overflow-hidden pb-5 pt-4 px-3 px-xl-4 px-xxl-5">

          <div class="map-popup invisible" id="map">
            <button class="btn btn-icon btn-light btn-sm shadow-sm rounded-circle" type="button" data-bs-toggle-class="invisible" data-bs-target="#map"><i class="fi-x fs-xs"></i></button>
            <div class="interactive-map" data-map-options-json="json/map-options-real-estate-rent.json"></div>
          </div>

          <nav class="mb-3 pt-md-2" aria-label="Breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="real-estate-home-v1.html">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Property for rent</li>
            </ol>
          </nav>

          <div class="d-sm-flex align-items-center justify-content-between pb-3 pb-sm-4">
            <h1 class="h2 mb-sm-0">All Properties</h1>
          </div>

          <div class="d-flex flex-sm-row flex-column align-items-sm-center align-items-stretch my-2">
            <div class="d-flex align-items-center flex-shrink-0">
              <label class="fs-sm me-2 pe-1 text-nowrap" for="sortby"><i class="fi-arrows-sort text-muted mt-n1 me-2"></i>Sort by:</label>
              <select class="form-select form-select-sm" id="sortby">
                <option>Newest</option>
                <option>Popularity</option>
                <option>Low - High Price</option>
                <option>High - Low Price</option>
                <option>High rating</option>
                <option>Average Rating</option>
              </select>
            </div>
            <hr class="d-none d-sm-block w-100 mx-4" />
            <div class="d-none d-sm-flex align-items-center flex-shrink-0 text-muted"><i class="fi-check-circle me-2"></i><span class="fs-sm mt-n1">{count} results</span></div>
          </div>

        



            {isLoading ?

              <div class="row g-4 py-4 ">
                <div className="col-sm-6 col-xl-4">

                  <div className="card shadow-sm card-hover border-0 h-100">
                    <div className="card-img-top card-img-hover"><div className="img-overlay" href="real-estate-single-v1.html"></div>
                      <div className="position-absolute start-0 top-0 pt-3 ps-3"></div>


                      <Skeleton style={{ width: "400px", height: "200px" }} />

                    </div>
                    <div className="card-body position-relative pb-3">
                      <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary"><Skeleton style={{ width: "80px", height: "7px" }} /></h4>
                      <h3 className="h6 mb-2 fs-base"><div className="nav-link stretched-link" href="real-estate-single-v1.html"><Skeleton style={{ width: "150px", height: "15px" }} /></div></h3>
                      <p className="mb-2 fs-sm text-muted"><Skeleton style={{ width: "100px", height: "10px" }} /></p>
                      <div className="fw-bold"><Skeleton style={{ width: "100px", height: "20px" }} /></div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"></div>
                  </div>
                </div>


                <div className="col-sm-6 col-xl-4">

                  <div className="card shadow-sm card-hover border-0 h-100">
                    <div className="card-img-top card-img-hover"><div className="img-overlay" href="real-estate-single-v1.html"></div>
                      <div className="position-absolute start-0 top-0 pt-3 ps-3"></div>


                      <Skeleton style={{ width: "400px", height: "200px" }} />

                    </div>
                    <div className="card-body position-relative pb-3">
                      <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary"><Skeleton style={{ width: "80px", height: "7px" }} /></h4>
                      <h3 className="h6 mb-2 fs-base"><div className="nav-link stretched-link" href="real-estate-single-v1.html"><Skeleton style={{ width: "150px", height: "15px" }} /></div></h3>
                      <p className="mb-2 fs-sm text-muted"><Skeleton style={{ width: "100px", height: "10px" }} /></p>
                      <div className="fw-bold"><Skeleton style={{ width: "100px", height: "20px" }} /></div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"></div>
                  </div>
                </div>


                <div className="col-sm-6 col-xl-4">

                  <div className="card shadow-sm card-hover border-0 h-100">
                    <div className="card-img-top card-img-hover"><div className="img-overlay" href="real-estate-single-v1.html"></div>
                      <div className="position-absolute start-0 top-0 pt-3 ps-3"></div>


                      <Skeleton style={{ width: "400px", height: "200px" }} />

                    </div>
                    <div className="card-body position-relative pb-3">
                      <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary"><Skeleton style={{ width: "80px", height: "7px" }} /></h4>
                      <h3 className="h6 mb-2 fs-base"><div className="nav-link stretched-link" href="real-estate-single-v1.html"><Skeleton style={{ width: "150px", height: "15px" }} /></div></h3>
                      <p className="mb-2 fs-sm text-muted"><Skeleton style={{ width: "100px", height: "10px" }} /></p>
                      <div className="fw-bold"><Skeleton style={{ width: "100px", height: "20px" }} /></div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"></div>
                  </div>
                </div>

                <div className="col-sm-6 col-xl-4">

                  <div className="card shadow-sm card-hover border-0 h-100">
                    <div className="card-img-top card-img-hover"><div className="img-overlay" href="real-estate-single-v1.html"></div>
                      <div className="position-absolute start-0 top-0 pt-3 ps-3"></div>


                      <Skeleton style={{ width: "400px", height: "200px" }} />

                    </div>
                    <div className="card-body position-relative pb-3">
                      <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary"><Skeleton style={{ width: "80px", height: "7px" }} /></h4>
                      <h3 className="h6 mb-2 fs-base"><div className="nav-link stretched-link" href="real-estate-single-v1.html"><Skeleton style={{ width: "150px", height: "15px" }} /></div></h3>
                      <p className="mb-2 fs-sm text-muted"><Skeleton style={{ width: "100px", height: "10px" }} /></p>
                      <div className="fw-bold"><Skeleton style={{ width: "100px", height: "20px" }} /></div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"></div>
                  </div>
                </div>

                <div className="col-sm-6 col-xl-4">

                  <div className="card shadow-sm card-hover border-0 h-100">
                    <div className="card-img-top card-img-hover"><div className="img-overlay" href="real-estate-single-v1.html"></div>
                      <div className="position-absolute start-0 top-0 pt-3 ps-3"></div>


                      <Skeleton style={{ width: "400px", height: "200px" }} />

                    </div>
                    <div className="card-body position-relative pb-3">
                      <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary"><Skeleton style={{ width: "80px", height: "7px" }} /></h4>
                      <h3 className="h6 mb-2 fs-base"><div className="nav-link stretched-link" href="real-estate-single-v1.html"><Skeleton style={{ width: "150px", height: "15px" }} /></div></h3>
                      <p className="mb-2 fs-sm text-muted"><Skeleton style={{ width: "100px", height: "10px" }} /></p>
                      <div className="fw-bold"><Skeleton style={{ width: "100px", height: "20px" }} /></div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"></div>
                  </div>
                </div>

                <div className="col-sm-6 col-xl-4">

                  <div className="card shadow-sm card-hover border-0 h-100">
                    <div className="card-img-top card-img-hover"><div className="img-overlay" href="real-estate-single-v1.html"></div>
                      <div className="position-absolute start-0 top-0 pt-3 ps-3"></div>


                      <Skeleton style={{ width: "400px", height: "200px" }} />

                    </div>
                    <div className="card-body position-relative pb-3">
                      <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary"><Skeleton style={{ width: "80px", height: "7px" }} /></h4>
                      <h3 className="h6 mb-2 fs-base"><div className="nav-link stretched-link" href="real-estate-single-v1.html"><Skeleton style={{ width: "150px", height: "15px" }} /></div></h3>
                      <p className="mb-2 fs-sm text-muted"><Skeleton style={{ width: "100px", height: "10px" }} /></p>
                      <div className="fw-bold"><Skeleton style={{ width: "100px", height: "20px" }} /></div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"></div>
                  </div>
                </div>

                <div className="col-sm-6 col-xl-4">

                  <div className="card shadow-sm card-hover border-0 h-100">
                    <div className="card-img-top card-img-hover"><div className="img-overlay" href="real-estate-single-v1.html"></div>
                      <div className="position-absolute start-0 top-0 pt-3 ps-3"></div>


                      <Skeleton style={{ width: "400px", height: "200px" }} />

                    </div>
                    <div className="card-body position-relative pb-3">
                      <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary"><Skeleton style={{ width: "80px", height: "7px" }} /></h4>
                      <h3 className="h6 mb-2 fs-base"><div className="nav-link stretched-link" href="real-estate-single-v1.html"><Skeleton style={{ width: "150px", height: "15px" }} /></div></h3>
                      <p className="mb-2 fs-sm text-muted"><Skeleton style={{ width: "100px", height: "10px" }} /></p>
                      <div className="fw-bold"><Skeleton style={{ width: "100px", height: "20px" }} /></div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"></div>
                  </div>
                </div>

                <div className="col-sm-6 col-xl-4">

                  <div className="card shadow-sm card-hover border-0 h-100">
                    <div className="card-img-top card-img-hover"><div className="img-overlay" href="real-estate-single-v1.html"></div>
                      <div className="position-absolute start-0 top-0 pt-3 ps-3"></div>


                      <Skeleton style={{ width: "400px", height: "200px" }} />

                    </div>
                    <div className="card-body position-relative pb-3">
                      <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary"><Skeleton style={{ width: "80px", height: "7px" }} /></h4>
                      <h3 className="h6 mb-2 fs-base"><div className="nav-link stretched-link" href="real-estate-single-v1.html"><Skeleton style={{ width: "150px", height: "15px" }} /></div></h3>
                      <p className="mb-2 fs-sm text-muted"><Skeleton style={{ width: "100px", height: "10px" }} /></p>
                      <div className="fw-bold"><Skeleton style={{ width: "100px", height: "20px" }} /></div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"></div>
                  </div>
                </div>

                <div className="col-sm-6 col-xl-4">

                  <div className="card shadow-sm card-hover border-0 h-100">
                    <div className="card-img-top card-img-hover"><div className="img-overlay" href="real-estate-single-v1.html"></div>
                      <div className="position-absolute start-0 top-0 pt-3 ps-3"></div>


                      <Skeleton style={{ width: "400px", height: "200px" }} />

                    </div>
                    <div className="card-body position-relative pb-3">
                      <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary"><Skeleton style={{ width: "80px", height: "7px" }} /></h4>
                      <h3 className="h6 mb-2 fs-base"><div className="nav-link stretched-link" href="real-estate-single-v1.html"><Skeleton style={{ width: "150px", height: "15px" }} /></div></h3>
                      <p className="mb-2 fs-sm text-muted"><Skeleton style={{ width: "100px", height: "10px" }} /></p>
                      <div className="fw-bold"><Skeleton style={{ width: "100px", height: "20px" }} /></div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"></div>
                  </div>
                </div>

                <div className="col-sm-6 col-xl-4">

                  <div className="card shadow-sm card-hover border-0 h-100">
                    <div className="card-img-top card-img-hover"><div className="img-overlay" href="real-estate-single-v1.html"></div>
                      <div className="position-absolute start-0 top-0 pt-3 ps-3"></div>


                      <Skeleton style={{ width: "400px", height: "200px" }} />

                    </div>
                    <div className="card-body position-relative pb-3">
                      <h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary"><Skeleton style={{ width: "80px", height: "7px" }} /></h4>
                      <h3 className="h6 mb-2 fs-base"><div className="nav-link stretched-link" href="real-estate-single-v1.html"><Skeleton style={{ width: "150px", height: "15px" }} /></div></h3>
                      <p className="mb-2 fs-sm text-muted"><Skeleton style={{ width: "100px", height: "10px" }} /></p>
                      <div className="fw-bold"><Skeleton style={{ width: "100px", height: "20px" }} /></div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap"></div>
                  </div>
                </div>

              </div> :
              <div class="row g-4 py-4 ">

               

                  {
                    propertyData && propertyData.count > 0 ? propertyData.results.map((property, index) => {
                      return (
                        <>
                        <div className="col-sm-6 col-xl-4">

                        <PropertyCard key={index} property={property} />

                        
                        </div>
                
            </>

                      )
                    }) : 
           
                    <div class="col-sm-6 col-xl-4">
						<div class="card shadow-sm card-hover border-0 h-100">
							<div class="card-body position-relative pb-3">
								<h4 class="mb-1 fs-xs fw-normal text-uppercase text-primary">Nothing Here!</h4>
								<h3 class="h6 mb-2 fs-base">
									No Properties Found!
								</h3>
							</div>
						</div>
					</div>
         


                  }

                



                 {propertyData && propertyData.count > 0 ? pageCount<=1?
  ""
  :
  <nav className="mt-2 mb-4" aria-label="Reviews pagination">
              <ul className="pagination">
                <li className="page-item d-sm-none"><span className="page-link page-link-static">1 / 5</span></li>
                {
                  previousProperties?
                  <li className="page-item"><span onClick={()=>{handlePagination(page -1)}} className="page-link" href="#" aria-label="Previous"><i className="fi-chevron-left"></i></span></li>
                  :
                  ""


                }
                {
                  page-2>0 && page-2<=pageCount?
                  <li className="page-item d-none d-sm-block"><span className="page-link" href="#" onClick={()=>{handlePagination(page - 2)}}>{page-2}</span></li>
                  :""


                }
                {
                 page>0 &&previousProperties?

                  <li className="page-item d-none d-sm-block"><span onClick={()=>{handlePagination(page-1)}} className="page-link" href="#">{page-1}</span></li>
                  :
                  ""


                }
                
                {
                  page>0?
                  <li className="page-item active d-none d-sm-block" aria-current="page"><span className="page-link">{page}<span className="visually-hidden">(current)</span></span></li>
                  :''


                }

                {
                  nextProperties?
                  <li className="page-item d-none d-sm-block"><span className="page-link" onClick={()=>{handlePagination(page+ 1)}}>{page+1}</span></li>

                  :""

                }
                {
                  page+2<=pageCount?
                  <li className="page-item d-none d-sm-block"><span className="page-link" href="#" onClick={()=>{handlePagination(page + 2)}}>{page+2}</span></li>
                  :""


                }

                {
                  page+4<=pageCount?
                  <li className="page-item d-none d-sm-block">...</li>
                  
                  :""
                }
                {
                  page+4<=pageCount?
                  <li className="page-item d-none d-sm-block"><span className="page-link"  onClick={()=>{handlePagination(pageCount&&pageCount)}} href="#"> {pageCount&&pageCount}</span></li>
                  
                  :"" 
                }
                {
                  nextProperties?
                  <li className="page-item"><span  onClick={()=>{handlePagination(page + 1)}} className="page-link" href="#" aria-label="Next"><i className="fi-chevron-right"></i></span></li>
                  :
                  ""

                }
                
                
              </ul>
            </nav>
            :""
}



      
      
            


              </div>}
            

          
        
          <button  onClick={()=>{setSidebar(!sidebar)}} class="btn btn-primary btn-sm w-100 rounded-0 fixed-bottom d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#filters-sidebar"><i class="fi-filter me-2"></i>Filters</button>
        </div>
      </div>
    </div>
  )
}

export default FindAccomodation