import React,{useRef,useState} from 'react'
import PageLoader from '../../../components/partners/basic/pageLoader'
import wallpaper from "../../../assets/images/login/3.jpg"
import logo from "../../../assets/images/site_logo.png"
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useLoginMutation ,useResendotpMutation,useVerifynumberMutation} from '../../../features/auth/authApiSlice'
import { logOut, setCredentials } from '../../../features/auth/authSlice'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../features/auth/authSlice'
import { store } from '../../../app/store'

function OTPPage() {

    const navigate = useNavigate();
    const formref=useRef(null)
    const[resend,{isLoading:isSending}]=useResendotpMutation()
    const user_id =useSelector(selectCurrentUser)
    const [resendsent,setResendsent]=useState(false)
    const [otpverified,setOtpVerfied]=useState(false)
const [wrongopt,setWrongotp]=useState(false)
const [verifynumber,{isLoading:isLoading}]=useVerifynumberMutation()




    const dispatch = useDispatch()
    const[incorrectdetails,setIncorectDetails]=useState(false)


    const [formData, setFormData] = useState({

        'otp':null,
        'user_id':null
          
      
      });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
      };
      
    
    const handleAlertClose=()=>{
      setIncorectDetails(false)
    }
    
    const handleLogout=()=>{
        dispatch(logOut())
        navigate("/partner/login")
        
      
      }




        const handleResendOtp=async()=>{

            try {
            
            
              console.log(user_id)
            
              const resendData= await resend({"user_id":user_id}).unwrap()
              console.log(resendData.status)
              if (resendData.status==="success"){
                setResendsent(true)
                formData.user_id=""
                formData.otp=""
          
                
              }
              
            
           
            }
              
                catch(e){
                  console.log(e)
          
                }
          
          }



          const handleOtp=async (e)=>{
            e.preventDefault()
            
              
            
                  try {
            
            
            console.log(user_id)
            console.log(formData)
            formData.user_id=user_id
            console.log(formData)
                    const verifyData= await verifynumber({otp:parseInt(formData.otp),user_id:user_id}).unwrap()
                    console.log(verifyData)
          
                    if (verifyData.status==="success"){
                      const currentState = store.getState().auth;
                      dispatch(setCredentials({
                        access:currentState.access , // new value for access token
                        refresh: currentState.refresh, // current refresh token
                        is_email_verified:"true", // current verification status
                        user_id: currentState.user_id // current user ID
                      }));
          
                      setOtpVerfied(true)
                      formData.user_id=""
                      formData.otp=""
                      navigate('/partner')
                      
                    }
                    
                 
                  }
                    
                      catch(e){
                        console.log(e)
                        if (e.status==401){
                          setWrongotp(true)
                        }
          
                      }
                  
                }
            

  return (
    <>
<PageLoader/>
<section className="sign-in-page bg-white">
            <div className="container-fluid p-0">
                <div className="row no-gutters">
                   
                <div class="col-sm-6 align-self-center">
                        <div class="sign-in-from">
                            <h1 class="mb-0">Verify OTP</h1>
                            <p>

                            Before proceeding, please check your Email for an OTP Code.

                            </p>
                            <form class="mt-4">
                              
  {
    resendsent?
    <div className="alert text-white bg-success" role="alert">
                              <div className="iq-alert-text">OTP Resent successfully!</div>
                              <button type="button"  onClick={()=>{setResendsent(false)}} className="close" data-dismiss="alert" aria-label="Close">
                              <i className="ri-close-line"></i>
                              </button>
                           </div> :""

  }


  {
    wrongopt? <div className="alert text-white bg-danger" role="alert">
                              <div className="iq-alert-text">Wrong OTP ,Try Again!</div>
                              <button type="button"  onClick={()=>{setWrongotp(false)}} className="close" data-dismiss="alert" aria-label="Close">
                              <i className="ri-close-line"></i>
                              </button>
                           </div>:""

  }
  {
    otpverified?<div className="alert text-white bg-danger" role="alert">
                              <div className="iq-alert-text">OTP Verified Succesfully!</div>
                              <button type="button"  onClick={()=>{setOtpVerfied(false)}} className="close" data-dismiss="alert" aria-label="Close">
                              <i className="ri-close-line"></i>
                              </button>
                           </div>:""

  }

                                
                                <div >
                                    <label for="exampleInputEmail1">OTP Code</label>
                                    <input name="otp" type='text' onChange={handleChange} class="form-control mb-0" id="exampleInputEmail1" placeholder="code"/>
                                </div>
                                
                               
                                <div className="d-inline-block w-100 mt-3">
                                  


                                    {
  isSending ? (
    
    <button type="button" className="btn btn-primary float-right">
    
    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
    resending ...</button>

    
  ) : isLoading ? (
    <button type="button" className="btn btn-primary float-right">
      
    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>

      Verifying ...
    </button>
  ) : (
    <button type="submit" onClick={handleOtp} className="btn btn-primary float-right">Verify & Proceed</button>

  )
}

                                   
                                </div>
                                <div>
                                <div className="w-100 mt-1 d-flex">
                                        <p className='mb-0'>If you did not receive a code, </p>
                                        <button type="button" onClick={handleResendOtp}  class="btn btn-link p-0 m-0 mt-0">
									Click here to request another
								</button>
                                    </div>
            
                                </div>

                                <div>
                                <div className="w-100 mt-1 d-flex">
                                        <p className='mb-0'> Don't want to verify now?</p>
                                        <button type="button" onClick={handleLogout}  class="btn btn-link p-0 me-2 mt-0">
                                        Logout Here
								</button>
                                    </div>
            
                                </div>



                               

                                
                            </form>
                        </div>
                    </div>
                    <div className="col-sm-6 text-center">
                        

                    <div className="sign-in-detail text-white" style={{ position: 'relative', overflow: 'hidden' }}>
    <div
        
    />
    <div
        className="background-image"
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: `url(${wallpaper}) no-repeat center center`,
            backgroundSize: 'cover',
            zIndex: 0
        }}
    />
    {
      /*
      
      <div className="content" style={{ position: 'relative', zIndex: 2 }}>
        <a className="sign-in-logo mb-5" href="#"></a>
        <div className="owl-carousel" data-autoplay="true" data-loop="true" data-nav="false" data-dots="true" data-items="1" data-items-laptop="1" data-items-tab="1" data-items-mobile="1" data-items-mobile-sm="1" data-margin="0">
            <div className="item">
                <h4 className="mb-1 text-white">Manage your orders</h4>
                <p>It is a long established fact that a reader will be distracted by the readable content.</p>
            </div>
            <div className="item">
                <img src="images/login/1.png" className="img-fluid mb-4" alt="logo" />
                <h4 className="mb-1 text-white">Manage your orders</h4>
                <p>It is a long established fact that a reader will be distracted by the readable content.</p>
            </div>
            <div className="item">
                <img src="images/login/1.png" className="img-fluid mb-4" alt="logo" />
                <h4 className="mb-1 text-white">Manage your orders</h4>
                <p>It is a long established fact that a reader will be distracted by the readable content.</p>
            </div>
        </div>
    </div>*/
    }
    
</div>


                    </div>
                </div>
            </div>
        </section>

    </>
  )
}

export default OTPPage